import {
  type SanityContentCarouselBlock,
  type SanityContentCarouselItem,
} from '@data/sanity/queries/types/blocks'

import Carousel from '@components/carousel'
import ProductCard from '@blocks/shop/product-card'
import BlogPostCard from '@blocks/blog/blog-post-card'
import Freeform from '@blocks/freeform'
import LinkBlock from '@blocks/link-block'
import Photo from '@components/photo'
import MuxVideo from '@components/video/mux-video'

interface ContentCarouselItemProps {
  item: SanityContentCarouselItem
}

type ContentCarouselProps = Pick<
  SanityContentCarouselBlock,
  'title' | 'items'
> & { hasContainer?: boolean }

const ContentCarouselItem = ({ item }: ContentCarouselItemProps) => {
  switch (item._type) {
    case 'blogPost': {
      const { blogPost } = item

      return <BlogPostCard post={blogPost} />
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = item

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'linkBlock': {
      const { title, link, image } = item

      return <LinkBlock title={title} link={link} image={image} />
    }

    case 'photo': {
      const image = item

      return (
        <Photo
          image={{
            ...image,
            _type: 'figure',
          }}
        />
      )
    }

    case 'product': {
      const { product } = item

      return <ProductCard product={product} showPrice />
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio, borderRadius } = item

      if (type === 'mux' && !!muxVideo) {
        return (
          <MuxVideo
            video={muxVideo}
            showControls={settings?.controls}
            autoplay={settings?.autoplay}
            loop={settings?.loop}
            muted={settings?.muted}
            borderRadius={borderRadius}
            customAspectRatio={aspectRatio}
          />
        )
      }

      return null
    }
  }
}

const ContentCarousel = ({
  title,
  items,
  hasContainer,
}: ContentCarouselProps) => (
  <Carousel
    title={title}
    hasArrows
    hasContainer={hasContainer}
    items={items?.map((item) => (
      <ContentCarouselItem key={item._key} item={item} />
    ))}
  />
)

export default ContentCarousel
