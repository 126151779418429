import NextLink from 'next/link'
import { useContext } from 'react'

import { SanityLinkBlock } from '@data/sanity/queries/types/blocks'
import { StringsContext } from '@lib/strings-context'
import { getLinkPageUrl, PageType } from '@lib/routes'
import Photo from '@components/photo'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'

type LinkBlockProps = Pick<SanityLinkBlock, 'title' | 'link' | 'image'>

const LinkBlock = ({ title, link, image }: LinkBlockProps) => {
  const strings = useContext(StringsContext)

  return (
    <NextLink
      href={getLinkPageUrl(link._type as PageType, link.slug?.current)}
      className="group relative"
    >
      <Photo
        image={image}
        className="relative after:absolute after:inset-0 after:bg-gradient-to-b after:from-[rgba(0,0,0,0)] after:to-[rgba(0,0,0,0.5)] after:opacity-80 after:transition-opacity after:duration-300 group-hover:after:opacity-100"
      />

      <div className="absolute bottom-0 inset-x-0 p-5 lg:p-8 space-y-5">
        <h3 className="text-white">{title}</h3>
        <span className="inline-flex items-center gap-2.5 text-sm text-white font-medium">
          <Button
            variant={ButtonVariant.FILLED}
            color={ButtonColor.WHITE}
            size={ButtonSize.SMALL}
            icon="ChevronDown"
            className="-rotate-90 !p-1 pointer-events-none"
          />
          {strings.buttonSeeMore}
        </span>
      </div>
    </NextLink>
  )
}

export default LinkBlock
